import React, {Component} from 'react';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';

const Error = () => {
  return (
    <div className="page">
      <h1>ERROR 404 BOIII</h1>
    </div>
  );
};

export default Error;
